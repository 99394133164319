<template>
  <div class="self-center">

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <img
        class="image-size"
        :src="
          params.banner_image
            ? params.banner_image
            : defaultBannerImage
        "
      >
    </b-overlay>

  </div>

</template>

<script>
export default {
  name: 'CellRendererImage',
  props: {
    params: Object,
  },
  data() {
    return {
      isLoading: true,
      defaultBannerImage: require('@/assets/images/placeholder/no-image.jpg'),
    }
  },
  computed: {
    chipColor() {
      return value => {
        if (value === 'active') return 'success'
        if (value === 'blocked') return 'danger'
        if (value === 'deactivated') return 'warning'
        return 'primary'
      }
    },
  },
  watch: {
    'params.banner_image': function (val, oldVal) {
      try {
        new URL(this.params.banner_image)
        this.$set(this, 'isLoading', false)
      } catch (error) {
        this.$set(this.params, 'banner_image', this.defaultBannerImage)
        this.$set(this, 'isLoading', false)
      }
    },
  },
  mounted() {
    try {
      new URL(this.params.banner_image)
      this.$set(this, 'isLoading', false)
    } catch (error) {
      this.$set(this.params, 'banner_image', this.defaultBannerImage)
      this.$set(this, 'isLoading', false)
    }
  },
}
</script>

<style lang="scss" scoped>
.image-size{
  width: 150px;
  height: 80px;
}

</style>
